import * as d3 from 'd3'

export default {
  methods: {

    link_Skyline(d_) {

      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {} // DO not Remark if possible 

      dataSource = {
        KEY1: d_.area,
        KEY2: d_.stage,
        KEY3: d_.code,
      }

      let req = this.currentStage.col

      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps(req, dataSource, this.ColumnProps)
      if(!request_) return

      // set for filterString 
      let filterString_ = ''

      // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }

      // combine filterString and inputFilter &&& emit request-action
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },


    call_Search(input=null) {
      
      let data_List = JSON.parse(JSON.stringify(this.rawData))
      this.search.results = []

      // apply search input ------------------------
      if(input != null) this.search.input = input
      input = input.toLowerCase()
      
      this.search.results = input ? data_List.filter(f => this.search.targets.some(col => f[this.skyline.refColumn].toLowerCase().includes(input))) : []
      this.filtering_Search(this.legends.stageStatus) // ---> below
    },
   
    




  }
}