import * as d3 from 'd3'

export default {
  methods: {

    draw_exception() {
      let except = null
      
      if(this.currentArea.status == 'OVERALL') {
        except = this.rawData.filter(f => 
          f.EXCEPT == 'Y' && 
          f[`${this.currentStage.col}`] !== '' && 
          f[`${this.currentStage.ref}`] !== undefined
        )
      } else {
        except = this.rawData.filter(f =>
          f.AREA ==  this.currentArea.status &&
          f.EXCEPT == 'Y' && 
          f[`${this.currentStage.col}`] !== '' && 
          f[`${this.currentStage.ref}`] !== undefined
        )
      }

      if (except.length == 0) return

      except.forEach((d,i) => {
 
        if(d[`${this.currentStage.pro}`] == 0 || d[`${this.currentStage.pro}`] == '') d.bColor = '#fff'
        else d.bColor = this.currentStage.color

        if(d[`${this.currentStage.pro}`] > 0 && d[`${this.currentStage.pro}`] < 100) d.opacity = .2
        else d.opacity  = 1

      })


      // Sorting Date
      let self_ = this
      function executeSort(a, b) { 
        if(a[`${self_.currentStage.ref}`] == b[`${self_.currentStage.ref}`]){ return 0} return a[`${self_.currentStage.ref}`] > b[`${self_.currentStage.ref}`] ? 1 : -1; 
      } 
      except.sort(executeSort)


      let self = this
      this.canvasExcept = this.svg
      .append('g')
      .attr('id', 'EXCEPTION')
      .attr('transform', `translate(100.5, 1020.5)`)
      .attr('font-family', 'roboto')

      this.canvasExcept
      .append('text')
      .attr('transform', `translate(0, 0)`)
      .attr('font-size', 11)
      .attr('fill', '#F35E90')
      .text('Blocks not included on the timeline')

      let hovorScale = 1.4
      let drawX = 12
      
      let width_ = this.currentStage.width
      let s_ = this.currentStage

      except.forEach((d, i) => {

        let x_ = drawX
        let y_ = 0
        
        let Boxes = this.canvasExcept
        .append('g')
        .attr('id', `BOX_${d[this.currentStage.col]}`)
        .attr('transform', `translate(${drawX}, 10)`)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          let factor = (hovorScale - 1) / 2
          let scale_x = width_ * factor
          let scale_y = self.skyline.skyBox.height * factor
          
          d3.select(this).moveToFront().transition().duration(150)
          .attr('transform', `translate(${x_-scale_x},${y_-scale_y+10}) scale(${hovorScale})`)

          d3.select('#DETAIL').transition().duration(200)
          .attr('opacity', 1)

          self.mouseOver_Detail(d) // ---> Detailed Information
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(150)
          .attr('transform', `translate(${x_},${y_+10}) scale(1)`)

          d3.select('#DETAIL').transition().delay(500).duration(300)
          .attr('opacity', self.detail.onOff == 'ON' ? 1 : 0)
        })
        .on('click', () => { this.link_Skyline(d[this.currentStage.col]) })

        Boxes
        .append('rect') // Background
        .attr('x', 0).attr('y',0)
        .attr('rx', 1)
        .attr('ry', 1)
        .attr('width', width_)
        .attr('height', this.skyline.skyBox.height)
        .attr('fill', '#fff')
        .attr('stroke', '#757575')
        .attr('stroke-width', .5)

        Boxes
        .append('rect') // Fill Color
        .attr('x', 0).attr('y',0)
        .attr('rx', 1)
        .attr('ry', 1)
        .attr('width', width_ * (d[this.currentStage.pro]/100))
        .attr('height', this.skyline.skyBox.height)
        .attr('fill', d.bColor)
        .attr('fill-opacity', 1)
      
        Boxes
        .append('text')
        .attr('x', width_/2)
        .attr('y', this.skyline.skyBox.height/2 + 1)
        .attr('font-family', this.skyline.skyBox.font)
        .attr('font-size', 9)
        .attr('fill', '#000')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .style('pointer-events', 'none')
        .text(d[this.currentStage.col])

        let Sch = this.canvasExcept
        .append('text')
        .attr('x', drawX + width_ + 8)
        .attr('y', 18)
        .attr('font-family', this.skyline.skyBox.font)
        .attr('font-size', 9)
        .attr('fill', '#000')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .style('pointer-events', 'none')
        .text(d[this.currentStage.ref])

        drawX = drawX + 100
      })


    },
    

  }
}
