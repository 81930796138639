export var ServiceRoutes = (navDataitems, callback) => {
  let rRoutes = []

  navDataitems.forEach(p => {
    let pRoute = {
      path: p.url,
      name: p.phaseName,
      component: { render (c) { return c('router-view') }},
      children: []
    }

    if(!!p.children) p.children.forEach(g => {
      let gRoute = {
        path: g.url,
        name: g.groupName,
        component: { render (c) { return c('router-view') }},
        children: []
      }

      if(!!g.children) g.children.forEach(t => {
        let tRoute = {
          path: t.url,
          name: t.itemName,
          component: () => t.direction == 'CUSTOM' ? import(`@/views/custom/${t.customCompoment}`) : import(`@/views/service/${t.direction}`),
          children: []
        }

        if(t.subItem == 'Y' && t.numChild > 0) {
          if(!!t.children) t.children.forEach(s => {
            let directionName = s.direction == 'CUSTOM' ? s.customCompoment : s.direction
            let sRoute = {
              path: s.url,
              name: t.itemName,
              component: () => s.direction == 'CUSTOM' ? import(`@/views/custom/${s.customCompoment}`) : import(`@/views/service/${s.direction}`),
              meta: { direction: directionName }
            }
            
            if (s.url == '/service/account_management/account_management/user_service/access_history') {
              let nRoute = {
                path: s.url,
                name: t.itemName,
                component: () => import('@/views/system/SysenvAccessHistory'),
                meta: { direction: s.direction }
              }
              tRoute.children.push(nRoute)
            }
            if (s.url == '/service/account_management/account_management/user_service/account_management') {
              let nRoute = {
                path: s.url,
                name: t.itemName,
                component: () => import('@/views/system/SvcAccessUserAccount'),
                meta: { direction: s.direction }
              }
              tRoute.children.push(nRoute)
            }
            if(s.direction) {
              // init parent's children at the first child met
              // which is not in unavailable
              // if(s.subItemStatus == 'SERVICE' && tRoute.children.length === 0) {
              if(s.subItemStatus == 'SERVICE' && !tRoute.redirect) {
                tRoute.redirect = s.url
                // change parent component none to pass the destination
                tRoute.component = { render (c) { return c('router-view') }}
              }
              
              // add direction activated route
              tRoute.children.push(sRoute)
            }
          })
        }
        if (t.url == '/service/project/progress_photo_video/progress_block_photo') {
          let nRoute = {
            path: t.url,
            name: t.itemName,
            component: () => import('@/views/service/ServiceBlockPhotoGallery'),
            meta: { direction: t.direction }
          }
          gRoute.children.push(nRoute)
        } 
        if (t.url == '/service/project/progress_photo_video/equipment_photo') {
          let nRoute = {
            path: t.url,
            name: t.itemName,
            component: () => import('@/views/service/ServiceEquipmentGallery'),
            meta: { direction: t.direction }
          }
          gRoute.children.push(nRoute)
        } 
        if (t.url == '/service/project/progress_photo_video/3d_rendering_images') {
          let nRoute = {
            path: t.url,
            name: t.itemName,
            component: () => import('@/views/service/ServiceRenderingGallery'),
            meta: { direction: t.direction }
          }
          gRoute.children.push(nRoute)
        } 

        if (t.url == '/service/project/progress_photo_video/hse_video') {
          let nRoute = {
            path: t.url,
            name: t.itemName,
            component: () => import('@/views/service/ServiceHseVideoGallery'),
            meta: { direction: t.direction }
          }
          gRoute.children.push(nRoute)
        } 

        if (t.url == '/service/project/progress_photo_video/project_progress_video') {
          let nRoute = {
            path: t.url,
            name: t.itemName,
            component: () => import('@/views/service/ServiceProjectVideoGallery'),
            meta: { direction: t.direction }
          }
          gRoute.children.push(nRoute)
        } 

        // if (t.url == '/service/construction/3d_viewer/pipe_status') {
        //   let nRoute = {
        //     path: t.url,
        //     name: t.itemName,
        //     component: () => import('@/views/public/3DViewerCUSTOM'),
        //     meta: { direction: t.direction }
        //   }
        //   gRoute.children.push(nRoute)
        // } 

        // if (t.url == '/service/construction/3d_viewer/pipe_status') {
        //   let nRoute = {
        //     path: t.url,
        //     name: t.itemName,
        //     component: () => import('@/views/public/3DViewerCUSTOM'),
        //     meta: { direction: t.direction }
        //   }
        //   gRoute.children.push(nRoute)
        // } 

        if(t.itemStatus == 'SERVICE' && (tRoute.children.length > 0 || t.direction)) {
          // init parent's children at the first children met
          // if(gRoute.children.length === 0) gRoute.redirect = `${rPath}${pPath}${gPath}${tPath}`
          if(!gRoute.redirect) gRoute.redirect = t.url
          // add direction activated route
          gRoute.children.push(tRoute)
        }
      })

      // init parent's children at the first children met
      // if(!pRoute.children.length === 0 && g.children.length > 0) pRoute.redirect = `${rPath}${pPath}${gPath}`
      if(!pRoute.redirect && g.children.length > 0) pRoute.redirect = g.url
      // add direction activated route
      pRoute.children.push(gRoute)
    })

    rRoutes.push(pRoute)
  })

  callback(rRoutes)
}
